@import '~app/mixins';

.listManagement {
  color: #666;
  font-size: 14px;

  .header {
    font-size: 26px;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    text-align: center;

    span {
      @include primary-color();
    }
  }

  &.reiautomator {
    .body .right .sheet {
      background-image: url('assets/images/reiautomator-listAutomator-banner.png');
    }
  }

  .body {
    display: flex;
    margin: 40px 0;

    .left {
      width: 50%;
      line-height: 22px;

      .title {
        font-size: 18px;
        padding-bottom: 4px;
        border-bottom: 2px solid #ddd;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .subTitle {
        font-weight: bold;
        margin-bottom: 8px;
      }

      button {
        width: 150px;
      }
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;

      .sheet {
        width: 90%;
        box-shadow: 5px 3px 44px rgba(0, 0, 0, 0.16);
        overflow: hidden;
        background: $color-white url('assets/images/listAutomator-banner.png') no-repeat left top -30px;
        background-size: 100% auto;

        .title {
          text-align: center;
          font-size: 22px;
          color: white;
          font-weight: bold;
          margin-top: 20px;
        }

        .image {
          width: 100%;
          margin-top: -40px;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 390px;
          margin: 140px auto 20px;

          .pricing {
            margin-bottom: 15px;

            .rates {
              color: $color-grey;
              background-color: white;
              border: 1px solid $color-grey-lighter;
              box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.16);
              font-size: 13px;
              padding: 3px 3px 10px;
              margin-bottom: 10px;

              .rate {
                display: flex;
                align-items: center;

                &:nth-child(odd) {
                  background-color: #f4f4f4;
                }

                > div {
                  border-right: 1px solid $color-grey-lighter;
                  border-bottom: 1px solid $color-grey-lighter;
                  padding: 10px 0 10px 13px;
                  text-align: left;

                  &:first-child {
                    width: 175px;
                    padding-left: 25px;
                  }

                  &:nth-child(2) {
                    width: 120px;
                    font-weight: bold;
                  }

                  &:nth-child(3) {
                    width: 80px;
                    font-weight: bold;
                  }

                  &:last-child {
                    width: 80px;
                  }

                  &:last-child {
                    border-right-style: none;
                  }
                }
              }
            }
          }

          .form {
            .field {
              color: $color-grey;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;

              label {
                font-weight: bold;
                white-space: nowrap;
                margin-bottom: 6px;
              }

              select {
                height: 40px;
                border-color: #cacaca;
              }
            }

            .message {
              font-weight: bold;
              text-align: center;
              margin: 10px 0;
              color: $color-red;

              &.success {
                color: $color-green;
              }
            }

            .buttons {
              display: flex;
              justify-content: space-between;
              margin-top: 25px;

              button {
                width: 140px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    font-size: 11px;
    color: #999;
    padding-top: 8px;
    border-top: 2px solid #ddd;
    text-align: center;
  }
}
